import { render, staticRenderFns } from "./CopeWithDetailDialog.vue?vue&type=template&id=2879fdfa&scoped=true&"
import script from "./CopeWithDetailDialog.vue?vue&type=script&lang=js&"
export * from "./CopeWithDetailDialog.vue?vue&type=script&lang=js&"
import style0 from "./CopeWithDetailDialog.vue?vue&type=style&index=0&id=2879fdfa&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2879fdfa",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\git\\kjp_oms_be\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2879fdfa')) {
      api.createRecord('2879fdfa', component.options)
    } else {
      api.reload('2879fdfa', component.options)
    }
    module.hot.accept("./CopeWithDetailDialog.vue?vue&type=template&id=2879fdfa&scoped=true&", function () {
      api.rerender('2879fdfa', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/const/components/CopeWithDetailDialog.vue"
export default component.exports