var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [{ name: "drag", rawName: "v-drag" }],
      attrs: {
        title: "详情",
        visible: _vm.dialogVisible,
        width: "1340px",
        "show-close": true,
        "append-to-body": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.cancel,
        top: "2%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "content",
        },
        [
          _c(
            "div",
            { staticClass: "header" },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: { model: _vm.form, "label-position": "top" },
                },
                [
                  _c(
                    "el-row",
                    { staticStyle: { width: "100%" }, attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 4.8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "账单号码" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "235px" },
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.form.invoiceNumber,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "invoiceNumber", $$v)
                                  },
                                  expression: "form.invoiceNumber",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 4.8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "账单金额" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "235px" },
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.form.invoiceAmount,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "invoiceAmount", $$v)
                                  },
                                  expression: "form.invoiceAmount",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 4.8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "币制" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "235px" },
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.form.currency,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "currency", $$v)
                                  },
                                  expression: "form.currency",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 4.8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "柜号" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "235px" },
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.form.containerNumber,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "containerNumber", $$v)
                                  },
                                  expression: "form.containerNumber",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 4.8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "提单号" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "235px" },
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.form.blNo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "blNo", $$v)
                                  },
                                  expression: "form.blNo",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { staticStyle: { width: "100%" }, attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 4.8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "条目" } },
                            [
                              _c("el-input-number", {
                                staticStyle: { width: "165px" },
                                attrs: {
                                  min: 0,
                                  max: 10,
                                  disabled:
                                    _vm.rowData.isShare ||
                                    _vm.rowData.status === 10,
                                },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.newAdd.apply(null, arguments)
                                  },
                                },
                                model: {
                                  value: _vm.form.entry,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "entry", $$v)
                                  },
                                  expression: "form.entry",
                                },
                              }),
                              _c(
                                "el-button",
                                {
                                  staticClass: "flex_c_c",
                                  staticStyle: {
                                    width: "60px",
                                    "margin-left": "10px",
                                  },
                                  attrs: {
                                    type: "primary",
                                    disabled:
                                      _vm.rowData.isShare ||
                                      _vm.rowData.status === 10,
                                  },
                                  on: { click: _vm.newAdd },
                                },
                                [_vm._v("增加 ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 4.8 } },
                        [
                          _c("el-form-item", [
                            _c("label", {
                              staticStyle: { display: "block", height: "19px" },
                              attrs: { slot: "label" },
                              slot: "label",
                            }),
                            _c(
                              "div",
                              { staticClass: "opt-btn" },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "preview flex_c_c",
                                    attrs: {
                                      disabled:
                                        _vm.rowData.isShare ||
                                        _vm.rowData.status === 10,
                                    },
                                    on: { click: _vm.preview },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/images/const/preview.png"),
                                      },
                                    }),
                                    _vm._v(" 预览文件 "),
                                  ]
                                ),
                                _vm.rowData.isIdentify &&
                                [0, 5].indexOf(_vm.rowData.status) > -1
                                  ? _c(
                                      "el-button",
                                      {
                                        staticClass: "recognition flex_c_c",
                                        attrs: {
                                          disabled:
                                            _vm.rowData.isShare ||
                                            _vm.rowData.status === 10,
                                        },
                                        on: { click: _vm.recognition },
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: require("@/assets/images/const/recognition.png"),
                                          },
                                        }),
                                        _vm._v(" 账单自动识别 "),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "main" },
            [
              _c(
                "el-table",
                {
                  ref: "multipleTable",
                  attrs: {
                    data: _vm.tableData.columns,
                    border: "",
                    width: "100%",
                    height: 506,
                    "header-cell-style": {
                      background: "#F7F7F7",
                      fontWeight: "400",
                      color: "#333333",
                    },
                  },
                },
                [
                  _vm._l(_vm.fileds, function (item, index) {
                    return _c(
                      "el-table-column",
                      {
                        key: index,
                        attrs: {
                          align: "center",
                          "header-align": "center",
                          resizable: "",
                          "show-overflow-tooltip": true,
                          prop: item.filed,
                          label: item.label,
                          width: item.width,
                          formatter: item.formatter,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm.rowData.status === 10 ||
                                  _vm.rowData.isShare
                                    ? _c(
                                        "p",
                                        [
                                          item.filed === "feeNameId"
                                            ? [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("feeName")(
                                                      scope.row[item.filed],
                                                      _vm.that
                                                    )
                                                  )
                                                ),
                                              ]
                                            : item.filed === "mblSendWayId"
                                            ? [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("sendWay")(
                                                      scope.row[item.filed],
                                                      _vm.that
                                                    )
                                                  )
                                                ),
                                              ]
                                            : [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      scope.row[item.filed]
                                                    ) +
                                                    " "
                                                ),
                                              ],
                                        ],
                                        2
                                      )
                                    : item.filed !== "feeNameId" &&
                                      item.filed !== "mblSendWayId"
                                    ? _c("el-input", {
                                        ref: `${item.filed + scope.$index}`,
                                        refInFor: true,
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          id: `${item.filed + scope.$index}`,
                                          placeholder: "请输入",
                                          disabled: item.disabled,
                                          oninput: item.keyup,
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.onInputChange(scope.row)
                                          },
                                          focus: function ($event) {
                                            return _vm.inputFocus(
                                              item.filed,
                                              scope.$index
                                            )
                                          },
                                        },
                                        nativeOn: {
                                          keydown: [
                                            function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              )
                                                return null
                                              return _vm.handleTab(
                                                item.filed,
                                                scope.$index + 1
                                              )
                                            },
                                            function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "tab",
                                                  9,
                                                  $event.key,
                                                  "Tab"
                                                )
                                              )
                                                return null
                                              return _vm.handleTab(
                                                item.filed,
                                                scope.$index + 1
                                              )
                                            },
                                            function ($event) {
                                              return _vm.arrow(
                                                $event,
                                                item.filed,
                                                scope.$index
                                              )
                                            },
                                          ],
                                        },
                                        model: {
                                          value: scope.row[item.filed],
                                          callback: function ($$v) {
                                            _vm.$set(scope.row, item.filed, $$v)
                                          },
                                          expression: "scope.row[item.filed]",
                                        },
                                      })
                                    : item.filed === "feeNameId" ||
                                      item.filed === "mblSendWayId"
                                    ? _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            id: `${item.filed + scope.$index}`,
                                            filterable: "",
                                            placeholder: "请选择",
                                            "filter-method": (e) =>
                                              _vm.filterMethod(
                                                e,
                                                item.filed === "feeNameId"
                                                  ? _vm.feeOptionsCopy
                                                  : _vm.mblOptionsCopy,
                                                scope.row,
                                                item.filed
                                              ),
                                          },
                                          on: {
                                            blur: function ($event) {
                                              return _vm.onBlur(
                                                item.filed,
                                                scope.row,
                                                scope.row[item.filed]
                                              )
                                            },
                                            focus: function ($event) {
                                              return _vm.onFocus(
                                                item.filed,
                                                scope.row,
                                                scope.row[item.filed]
                                              )
                                            },
                                            change: function ($event) {
                                              return _vm.onChange(
                                                item.filed,
                                                scope.row,
                                                scope.row[item.filed]
                                              )
                                            },
                                            "visible-change": (e) =>
                                              _vm.visibleChange(e, item.filed),
                                          },
                                          nativeOn: {
                                            keydown: function ($event) {
                                              return _vm.arrow(
                                                $event,
                                                item.filed,
                                                scope.$index
                                              )
                                            },
                                          },
                                          model: {
                                            value: scope.row[item.filed],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                item.filed,
                                                $$v
                                              )
                                            },
                                            expression: "scope.row[item.filed]",
                                          },
                                        },
                                        [
                                          scope.row.isSendWay &&
                                          item.filed === "mblSendWayId"
                                            ? _vm._l(
                                                _vm.mblOptions,
                                                function (a) {
                                                  return _c("el-option", {
                                                    key: a.value,
                                                    attrs: {
                                                      label: a.label,
                                                      value: a.value,
                                                    },
                                                  })
                                                }
                                              )
                                            : item.filed === "feeNameId"
                                            ? _vm._l(
                                                _vm.feeOptions,
                                                function (a) {
                                                  return _c("el-option", {
                                                    key: a.value,
                                                    attrs: {
                                                      label: a.label,
                                                      value: a.value,
                                                    },
                                                  })
                                                }
                                              )
                                            : _vm._e(),
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [
                        _c("template", { slot: "header" }, [
                          _vm.ifFiled(item.filed)
                            ? _c("div", [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("* "),
                                ]),
                                _vm._v(_vm._s(item.label) + " "),
                              ])
                            : _vm._e(),
                        ]),
                      ],
                      2
                    )
                  }),
                  _vm.rowData.status !== 10 && !_vm.rowData.isShare
                    ? _c("el-table-column", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          align: "center",
                          label: "操作",
                          width: "100",
                          fixed: "right",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "tab-bt flex_c_c" },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: require("@/assets/images/const/delete.png"),
                                          title: "删除",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteRow(scope.$index)
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3091623893
                        ),
                      })
                    : _vm._e(),
                ],
                2
              ),
              _c("div", { staticClass: "custom-total flex_c_c" }, [
                _c("div", [
                  _vm._v(" 总金额："),
                  _c("span", [_vm._v(_vm._s(_vm.total) + " USD")]),
                ]),
                _c("div", [
                  _vm._v(" 总条数："),
                  _c("span", [_vm._v(_vm._s(_vm.tableData.columns.length))]),
                ]),
              ]),
            ],
            1
          ),
        ]
      ),
      _c(
        "span",
        { staticClass: "footers", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "div",
            { staticClass: "cancel flex_c_c", on: { click: _vm.cancel } },
            [_vm._v("取消")]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "ok flex_c_c",
              on: { click: _vm.ok },
            },
            [_vm._v("提交")]
          ),
          _c(
            "span",
            {
              staticClass: "optDocument flex_c_c",
              on: {
                click: function ($event) {
                  _vm.dialogVisible1 = true
                },
              },
            },
            [_c("i", { staticClass: "el-icon-question" }), _vm._v("操作说明 ")]
          ),
        ]
      ),
      _c("OptDocumentDialog", {
        attrs: { dialogVisible: _vm.dialogVisible1, type: "CopeWith" },
        on: {
          ok: function ($event) {
            _vm.dialogVisible1 = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }