<template>
  <el-dialog
    v-drag
    title="详情"
    :visible.sync="dialogVisible"
    width="1340px"
    :show-close="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
    top="2%"
  >
    <div v-loading="loading" class="content">
      <div class="header">
        <el-form ref="form" :model="form" label-position="top">
          <el-row :gutter="20" style="width: 100%;">
            <el-col :span="4.8">
              <el-form-item label="账单号码">
                <el-input
                  v-model="form.invoiceNumber"
                  style="width: 235px"
                  disabled
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4.8">
              <el-form-item label="账单金额">
                <el-input
                  v-model="form.invoiceAmount"
                  style="width: 235px"
                  disabled
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4.8">
              <el-form-item label="币制">
                <el-input
                  v-model="form.currency"
                  style="width: 235px"
                  disabled
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4.8">
              <el-form-item label="柜号">
                <el-input
                  v-model="form.containerNumber"
                  style="width: 235px"
                  disabled
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4.8">
              <el-form-item label="提单号">
                <el-input
                  v-model="form.blNo"
                  style="width: 235px"
                  disabled
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20" style="width: 100%;">
            <el-col :span="4.8">
              <el-form-item label="条目">
                <el-input-number
                  v-model="form.entry"
                  :min="0"
                  :max="10"
                  :disabled="rowData.isShare || rowData.status === 10"
                  style="width: 165px"
                  @keyup.enter.native="newAdd"
                ></el-input-number>
                <el-button
                  type="primary"
                  style="width: 60px;margin-left: 10px;"
                  class="flex_c_c"
                  :disabled="rowData.isShare || rowData.status === 10"
                  @click="newAdd"
                >增加
                </el-button>
              </el-form-item>
            </el-col>
            <el-col :span="4.8">
              <el-form-item>
                <label slot="label" style="display: block;height: 19px;"></label>
                <div class="opt-btn">
                  <el-button
                    class="preview flex_c_c"
                    :disabled="rowData.isShare || rowData.status === 10"
                    @click="preview">
                    <img src="@/assets/images/const/preview.png" />
                    预览文件
                  </el-button>
                  <el-button
                    v-if="rowData.isIdentify && [0, 5].indexOf(rowData.status) > -1"
                    class="recognition flex_c_c"
                    :disabled="rowData.isShare || rowData.status === 10"
                    @click="recognition">
                    <img src="@/assets/images/const/recognition.png" />
                    账单自动识别
                  </el-button>
                </div>
              </el-form-item>
            </el-col>
            <!-- <el-col class="col-btn" :span="14.4">
              <el-form-item>
                <label slot="label" style="display: block;height: 19px;"></label>
                <div class="opt-btn">
                  <el-upload
                    ref="uploadDemo"
                    class="upload-demo"
                    action="#"
                    list-type="picture"
                    :http-request="httpRequest"
                    :show-file-list="false"
                  >
                  </el-upload>
                  <el-button
                    class="preview flex_c_c"
                    :disabled="rowData.isShare || rowData.status === 10"
                    @click="importFile">
                    <img src="@/assets/images/bill/import.png" />
                    导入
                  </el-button>
                  <el-button
                    class="download flex_c_c"
                    :disabled="rowData.isShare || rowData.status === 10"
                    @click="download">
                    <img src="@/assets/images/bill/download-1.png" />
                    下载模版
                  </el-button>
                </div>
              </el-form-item>
            </el-col> -->
          </el-row>
        </el-form>
      </div>
      <div class="main">
        <el-table
          ref="multipleTable"
          :data="tableData.columns"
          border
          width="100%"
          :height="506"
          :header-cell-style="{
            background: '#F7F7F7',
            fontWeight: '400',
            color: '#333333',
          }"
        >
          <el-table-column
            v-for="(item, index) in fileds"
            :key="index"
            align="center"
            header-align="center"
            resizable
            :show-overflow-tooltip="true"
            :prop="item.filed"
            :label="item.label"
            :width="item.width"
            :formatter="item.formatter"
          >
            <template slot="header">
              <div v-if="ifFiled(item.filed)">
                <span style="color: red;">* </span>{{ item.label }}
              </div>
            </template>
            <template slot-scope="scope">
              <p v-if="rowData.status === 10 || rowData.isShare">
                <template v-if="item.filed === 'feeNameId'">{{
                  scope.row[item.filed] | feeName(that)
                }}</template>
                <template v-else-if="item.filed === 'mblSendWayId'">{{
                  scope.row[item.filed] | sendWay(that)
                }}</template>
                <template v-else>
                  {{ scope.row[item.filed] }}
                </template>
              </p>
              <el-input
                v-else-if="
                  item.filed !== 'feeNameId' && item.filed !== 'mblSendWayId'
                "
                :id="`${item.filed + scope.$index}`"
                :ref="`${item.filed + scope.$index}`"
                v-model="scope.row[item.filed]"
                placeholder="请输入"
                style="width: 100%"
                :disabled="item.disabled"
                :oninput="item.keyup"
                @change="onInputChange(scope.row)"
                @keydown.enter.native="handleTab(item.filed, scope.$index + 1)"
                @keydown.tab.native="handleTab(item.filed, scope.$index + 1)"
                @keydown.native="arrow($event, item.filed, scope.$index)"
                @focus="inputFocus(item.filed, scope.$index)"
              ></el-input>
              <el-select
                v-else-if="item.filed === 'feeNameId' || item.filed === 'mblSendWayId'"
                :id="`${item.filed + scope.$index}`"
                v-model="scope.row[item.filed]"
                style="width: 100%"
                filterable
                placeholder="请选择"
                :filter-method="
                  (e) =>
                    filterMethod(
                      e,
                      item.filed === 'feeNameId' ? feeOptionsCopy : mblOptionsCopy,
                      scope.row,
                      item.filed
                    )
                "
                @blur="onBlur(item.filed, scope.row, scope.row[item.filed])"
                @focus="onFocus(item.filed, scope.row, scope.row[item.filed])"
                @change="onChange(item.filed, scope.row, scope.row[item.filed])"
                @keydown.native="arrow($event, item.filed, scope.$index)"
                @visible-change="(e) => visibleChange(e, item.filed)"
              >
                <template v-if="scope.row.isSendWay && item.filed === 'mblSendWayId'">
                  <el-option
                    v-for="a in mblOptions"
                    :key="a.value"
                    :label="a.label"
                    :value="a.value"
                  ></el-option>
                </template>
                <template v-else-if="item.filed === 'feeNameId'">
                  <el-option
                    v-for="a in feeOptions"
                    :key="a.value"
                    :label="a.label"
                    :value="a.value"
                  ></el-option>
                </template>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column
            v-if="rowData.status !== 10 && !rowData.isShare"
            align="center"
            label="操作"
            width="100"
            fixed="right"
            style="width: 100%"
          >
            <template slot-scope="scope">
              <div class="tab-bt flex_c_c">
                <!-- <template v-if="scope.$index !== tableData.columns.length - 1"> -->
                <img
                  src="@/assets/images/const/delete.png"
                  title="删除"
                  @click="deleteRow(scope.$index)"
                />
                <!-- </template> -->
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="custom-total flex_c_c">
          <div>
            总金额：<span>{{ total }} USD</span>
          </div>
          <div>
            总条数：<span>{{ tableData.columns.length }}</span>
          </div>
        </div>
      </div>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取消</div>
      <div v-loading="loading" class="ok flex_c_c" @click="ok">提交</div>
      <span class="optDocument flex_c_c" @click="dialogVisible1 = true">
        <i class="el-icon-question"></i>操作说明
      </span>
    </span>
    <OptDocumentDialog
      :dialogVisible="dialogVisible1"
      type="CopeWith"
      @ok="dialogVisible1 = false"
    ></OptDocumentDialog>
  </el-dialog>
</template>

<script>
import OptDocumentDialog from './OptDocumentDialog'
import {
  getPayableDetail,
  updatePayableDetail,
  GetFeeNameList,
  SupplierPayableFeeIdentify
} from '@/api/fee'
import { getMBLSendWay } from '@/api/bill'
import { debounce } from '@/utils/common'
import * as XLSX from 'xlsx'
import config from '@/utils/config'
export default {
  components: { OptDocumentDialog },
  filters: {
    // eslint-disable-next-line consistent-this
    sendWay(id, that) {
      if (id && that.mblOptions.length > 0) {
        return that.mblOptionsCopy.find((a) => a.value === +id)?.label
      } else {
        return ''
      }
    },
    // eslint-disable-next-line consistent-this
    feeName(id, that) {
      if (id && that.feeOptions.length > 0) {
        return that.feeOptionsCopy.find((a) => a.value === +id)?.label
      } else {
        return ''
      }
    }
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    rowData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      that: this,
      mblOptions: [],
      mblOptionsCopy: [],
      feeOptions: [],
      feeOptionsCopy: [],
      tableData: {
        fileds: [],
        columns: []
      },
      contrastObj: {
        feeNameId: '',
        mblSendWayId: '',
        isSendWay: false,
        qty: '1',
        unitPrice: '',
        remark: '',
        totalAmount: ''
      },
      dialogVisible1: false,
      form: {},
      newAddFlag: false,
      loading: false
    }
  },
  computed: {
    total() {
      return this.tableData.columns.reduce((prev, next) => {
        return (prev += +next.qty * +next.unitPrice)
      }, 0).toFixed(2)
    },
    fileds() {
      const arr = [
        {
          filed: 'feeNameId',
          label: '费用项',
          width: ''
        },
        {
          filed: 'mblSendWayId',
          label: '派送方式',
          width: ''
        },
        {
          filed: 'qty',
          label: '数量',
          width: '120',
          keyup: `value=value.replace(/[^0-9.]/g, '')`
        },
        {
          filed: 'unitPrice',
          label: '单价',
          width: '120',
          keyup: `value=value.replace(/[^0-9.]/g, '')`
        },
        {
          filed: 'totalAmount',
          label: '金额',
          width: '120',
          disabled: true
        },
        {
          filed: 'remark',
          label: '备注',
          width: this.rowData.status !== 10 && !this.rowData.isShare ? '299' : '399'
        }
      ]
      return arr
    }
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.tableData.columns = []
        this.getMBLSendWay()
        this.GetFeeNameList()
        this.getList()
        this.rowData.id &&
          (this.form = {
            ...this.rowData,
            entry: 0
          })
      }
    }
  },
  methods: {
    ifFiled(v) {
      if (v === 'feeNameId' || v === 'qty' || v === 'unitPrice' || v === 'totalAmount') {
        return true
      } else {
        return false
      }
    },
    // 费用项
    async GetFeeNameList() {
      try {
        const res = await GetFeeNameList({ feeTypeCode: 'MBLF' })
        const feeOptions = res.data.map((item) => {
          return {
            label: `${item.code} - ${item.name}`,
            value: item.id,
            isSendWay: item.isSendWay
          }
        })
        this.feeOptions = feeOptions
        this.feeOptionsCopy = feeOptions
      } catch (error) {
        console.log(error)
      }
    },
    // 派送方式
    async getMBLSendWay() {
      try {
        const res = await getMBLSendWay({ mblId: this.rowData.mblId })
        if (res.success) {
          const mblOptions = res.data.map((item) => {
            return {
              label: item.typeName,
              value: item.id
            }
          })
          this.mblOptions = mblOptions
          this.mblOptionsCopy = mblOptions
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.log(error)
      }
    },
    async getList() {
      this.loading = true
      try {
        const result = await getPayableDetail({ id: this.rowData.id })
        this.loading = false
        if (result.success) {
          this.tableData.columns =
            result.data &&
            result.data.map((a) => {
              const isSendWay = this.feeOptionsCopy.find(
                (b) => b.value === +a.feeNameId
              )?.isSendWay
              return {
                ...a,
                feeNameId: +a.feeNameId,
                mblSendWayId: a.mblSendWayId ? +a.mblSendWayId : null,
                isSendWay: isSendWay || false
              }
            })
          // if (this.rowData.status !== 10 && !this.rowData.isShare) {
          //   this.tableData.columns.push({
          //     feeNameId: '',
          //     mblSendWayId: '',
          //     isSendWay: false,
          //     qty: '1',
          //     unitPrice: '',
          //     remark: '',
          //     totalAmount: ''
          //   })
          // }
        } else {
          this.$message.error(result.errorMessage)
        }
      } catch (error) {
        this.loading = false
        console.error(error)
      }
    },
    cancel() {
      this.contrastObj = {
        feeNameId: '',
        mblSendWayId: '',
        isSendWay: false,
        qty: '1',
        unitPrice: '',
        remark: '',
        totalAmount: ''
      }
      this.tableData.columns = []
      this.$emit('cancel')
    },
    deleteRow(index) {
      this.tableData.columns.splice(index, 1)
    },
    async ok() {
      if (this.rowData.isShare) {
        this.$message.warning('分摊不可进行操作')
        return
      }
      if (+this.form.invoiceAmount !== +this.total) {
        this.$message.error('账单录入金额和账单金额不同')
        return
      }
      let flag1 = false
      let flag2 = false
      const tableData = [...this.tableData.columns]
      tableData.forEach((a, i) => {
        const { feeNameId, qty, unitPrice } = a
        if (
          (!feeNameId || !qty || +qty === 0 || !unitPrice || +unitPrice === 0)
        ) {
          flag1 = true
        }
        if (
          i + 1 === tableData.length &&
          !feeNameId &&
          !qty &&
          !unitPrice
        ) {
          flag2 = true
        }
      })
      flag2 && tableData.splice(tableData.length - 1, 1)
      if (flag1) {
        this.$message.warning('费用项、数量、价格不能为空，且数量价格不能为0')
        return
      }
      if (!flag2) {
        const { feeNameId, qty, unitPrice } = tableData[tableData.length - 1]
        if (
          !feeNameId ||
          !qty ||
          !unitPrice ||
          +qty === 0 ||
          +unitPrice === 0
        ) {
          this.$message.warning(
            '费用项、数量、价格不能为空，且数量价格不能为0'
          )
          return
        }
      }
      const params = {
        data: tableData,
        invoiceId: this.rowData.id
      }
      try {
        const result = await updatePayableDetail(params)
        if (result.success) {
          this.cancel()
          this.$emit('ok')
          this.$message.success(result.errorMessage)
        } else {
          this.$message.error(result.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    },
    // 自动计算金额
    onInputChange(item) {
      const { qty, unitPrice } = item
      qty &&
        unitPrice &&
        (item.totalAmount =
          +qty.toString().replace(/[^0-9.]/g, '') *
          +unitPrice.toString().replace(/[^0-9.]/g, ''))
      if (!qty || !unitPrice) {
        item.totalAmount = ''
      }
    },
    // 按tab换行
    handleTab(filed, index) {
      const length = this.tableData.columns.length
      this.newAddFlag = false
      if (filed === 'remark' && index === length) {
        this.tableData.columns.push({
          feeNameId: '',
          mblSendWayId: '',
          isSendWay: false,
          qty: '1',
          unitPrice: '',
          remark: '',
          totalAmount: ''
        })
      }
    },
    // 获取焦点默认选中
    onFocus(filed, item) {
      if (filed === 'feeNameId') {
        item.mblSendWayId = ''
        !item.feeNameId && (item.feeNameId = this.feeOptions[0]?.value)
        !item.isSendWay && (item.isSendWay = this.feeOptions[0]?.isSendWay)
      } else {
        item.isSendWay && (item.mblSendWayId = this.mblOptions[0]?.value)
      }
    },
    onBlur(filed, item, v) {
      if (filed === 'feeNameId') {
        item.isSendWay = this.feeOptionsCopy.find(
          (a) => a.value === v
        )?.isSendWay
      }
    },
    onChange(filed, item, v) {
      if (filed === 'feeNameId') {
        item.isSendWay = this.feeOptionsCopy.find(
          (a) => a.value === v
        )?.isSendWay
      }
    },
    visibleChange(e, filed) {
      if (e) {
        if (filed === 'feeNameId') {
          this.feeOptions = this.feeOptionsCopy
        } else {
          this.mblOptions = this.mblOptionsCopy
        }
      }
    },
    // 自定义下拉框搜索
    filterMethod: debounce(async function(e, array, item, filed) {
      if (e) {
        const value = e.toLowerCase()
        const arr = [...array].filter((a) => a.label.toLowerCase().includes(value))
        await filed === 'feeNameId' && (this.feeOptions = arr)
        await filed === 'mblSendWayId' && (this.mblOptions = arr)
        if (arr[0]?.value && filed === 'feeNameId') {
          item.feeNameId = arr[0]?.value
        }
        if (arr[0]?.value && filed === 'mblSendWayId') {
          item.mblSendWayId = arr[0]?.value
        }
      } else {
        this.feeOptions = this.feeOptionsCopy
        this.mblOptions = this.mblOptionsCopy
      }
    }, 300),
    // 键盘箭头事件
    arrow(e, filed, index) {
      const keyCode = e.keyCode
      // 当前dom元素id
      const id = filed + index
      // 光标长度
      const cursurPosition = document.getElementById(id).selectionStart
      // 文本长度
      const textLenght = document.getElementById(id).value.length
      // 数据数组
      const tableData = this.tableData.columns
      switch (keyCode) {
        case 37:
          if (cursurPosition === 0) {
            if (filed === 'qty') {
              if (
                index !== tableData.length &&
                tableData[index].isSendWay &&
                !tableData[index].mblSendWayId
              ) {
                tableData[index].mblSendWayId = this.mblOptionsCopy[0].value
              } else if (
                index === tableData.length &&
                this.contrastObj.isSendWay &&
                !this.contrastObj.mblSendWayId
              ) {
                this.contrastObj.mblSendWayId = this.mblOptionsCopy[0].value
              }
            }
            filed === 'qty' &&
              document.getElementById(`mblSendWayId${index}`).focus()
            filed === 'unitPrice' &&
              document.getElementById(`qty${index}`).focus()
            filed === 'remark' &&
              document.getElementById(`unitPrice${index}`).focus()
          }
          break
        case 39:
          if (cursurPosition === textLenght) {
            filed === 'qty' &&
              document.getElementById(`unitPrice${index}`).focus()
            filed === 'unitPrice' &&
              document.getElementById(`remark${index}`).focus()
          }
          break
        case 38:
          if (index) {
            const isCanInputIndex = tableData.findLastIndex(
              (a, i) => i < index
            )
            filed === 'qty' &&
              document.getElementById(`qty${isCanInputIndex}`).focus()
            filed === 'unitPrice' &&
              document.getElementById(`unitPrice${isCanInputIndex}`).focus()
            filed === 'remark' &&
              document.getElementById(`remark${isCanInputIndex}`).focus()
          }
          break
        case 40:
          if (index + 1 < tableData.length) {
            let isCanInputIndex = tableData.findIndex((a, i) => i > index)
            isCanInputIndex =
              isCanInputIndex === -1 ? tableData.length : isCanInputIndex
            filed === 'qty' &&
              document.getElementById(`qty${isCanInputIndex}`).focus()
            filed === 'unitPrice' &&
              document.getElementById(`unitPrice${isCanInputIndex}`).focus()
            filed === 'remark' &&
              document.getElementById(`remark${isCanInputIndex}`).focus()
          }
          break
      }
    },
    inputFocus(filed, index) {
      if (filed !== 'remark') {
        this.$refs[filed + index][0].select()
      }
    },
    // 预览文件
    preview() {
      window.open(
        `/backEnd/api/5KXQFWYH/File/preview?guid=${this.rowData.fileGuid}`,
        '_blank'
      )
    },
    newAdd() {
      if (!this.form.entry) return
      this.newAddFlag = true
      let i = 0
      while (i < this.form.entry) {
        this.tableData.columns.push({ ...this.contrastObj })
        i++
      }
    },
    importFile() {
      this.$refs.uploadDemo.$refs['upload-inner'].$refs.input.click()
    },
    download() {
      const elink = document.createElement('a')
      elink.style.display = 'none'
      elink.href = `/backEnd/api/${config.supplyCode}/file/downLoadTemplate?guid=d3f687e8d8da4d42a001ff52dcc6dfe1`
      document.body.appendChild(elink)
      elink.click()
      URL.revokeObjectURL(elink.href)
      document.body.removeChild(elink)
    },
    httpRequest({ file }) {
      this.fileList = [{
        fileName: file.name,
        fileSize: Math.ceil(file.size / 1024)
      }]
      const reader = new FileReader()
        const that = this
        reader.onload = async function(e) {
          const data = e.target.result
          const wb = XLSX.read(data, { type: 'binary' })
          const jdata = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]])
          const newData = jdata
            .map((a) => {
              const newKey = Object.keys(a).sort()
              const newObj = {}
              newKey.forEach((b, i) => {
                newObj[newKey[i].toLowerCase()] = a[newKey[i]]
              })
              return newObj
            })
            .map((a) => {
              const fee = that.feeOptionsCopy.find(b => {
                if (b.label.split('-')[0].trim() === a['*费用代码']) {
                  return b
                }
              })
              return {
                feeNameId: fee.value,
                mblSendWayId: '',
                isSendWay: fee.isSendWay,
                qty: a['*数量'] || 1,
                unitPrice: a['*单价'] || '',
                remark: a['备注'] || '',
                totalAmount: a['*数量'] && a['*单价'] ? a['*数量'] * a['*单价'] : ''
              }
            })
            that.tableData.columns.push(...newData)
        }
      reader.readAsBinaryString(file)
    },
    async recognition() {
      try {
        const res = await SupplierPayableFeeIdentify({ payableInvoiceId: this.rowData.id })
        if (res.success) {
          if (!res.data.length) {
            this.$message.error('识别失败')
            return
          }
          const arr = res.data.map(a => {
            const isSendWay = this.feeOptionsCopy.find(
                (b) => b.value === +a.feeNameId
              )?.isSendWay
            return {
              ...a,
              isSendWay: isSendWay || false
            }
          })
          this.tableData.columns = arr
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .el-table--border .el-table__cell:first-child .cell {
  padding: 0;
}
/deep/ .el-table tr th {
  height: 40px;
  padding: 0;
}
/deep/ .el-table tr td {
  height: 30px;
  padding: 0;
}
/deep/ .el-table tr td .cell {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  height: 30px;
}
/deep/ .el-dialog__body {
  padding: 20px;
}
.content {
  width: 100%;
  height: auto;
  margin: 0 auto;
  overflow: hidden;
  .header {
    display: flex;
    .el-form {
      display: flex;
      flex-wrap: wrap;
    }
    .el-form-item {
      margin-bottom: 15px;
      & /deep/ .el-form-item__label {
        line-height: normal;
      }
      & /deep/ .el-form-item__content {
        display: flex;
      }
    }
    .col-btn {
      position: absolute;
      right: 24px;
    }
    .opt-btn {
      display: flex;
      justify-content: space-between;
      width: 235px;
      & > button {
        height: 40px;
        border-radius: 4px 4px 4px 4px;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        box-sizing: border-box;
        & /deep/span {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
  .main {
    overflow: hidden;
    border-radius: 4px 4px 4px 4px;
    :deep(.el-input__inner) {
      height: 30px;
      border: 0;
    }
    :deep(.el-input__suffix) {
      width: 30px;
      height: 30px;
      right: 0;
    }
    :deep(.el-input__suffix-inner) {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
    :deep(.el-select__caret) {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 14px;
      height: 14px;
      line-height: inherit;
    }
    .custom-total {
      width: 100%;
      height: 40px;
      background: #f7f7f7;
      border-radius: 0px 0px 4px 4px;
      border: 1px solid #eeeeee;
      border-top: 0;
      box-sizing: border-box;
      span {
        color: #fe453d;
        font-weight: bold;
      }
      div + div {
        margin-left: 20px;
      }
    }
  }
  .upload-demo {
    display: none;
  }
  .preview {
    width: 104px;
    background: #35ba83;
    color: #ffffff;
    border: 0;
  }
  .recognition {
    width: 120px;
    border: 1px solid #35BA83;
    color: #35BA83;
    background: transparent;
  }
  .download {
    width: 112px;
    height: 40px;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #35BA83;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #35BA83;
    box-sizing: border-box;
    background: transparent;
    & /deep/span {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .total {
    width: 100%;
    height: 40px;
    background: #f7f7f7;
    border-top: 1px solid #eeeeee;
    box-sizing: border-box;
    font-size: 12px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    span {
      color: #fe453d;
      font-weight: bold;
    }
  }
  .tab-bt {
    height: 100%;
    img {
      cursor: pointer;
    }
  }
}
.footers {
  width: 100%;
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #eeeeee;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-right: 16px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    margin-right: 16px;
  }
  .optDocument {
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #fe453d;
    cursor: pointer;
  }
}
</style>
